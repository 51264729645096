import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useDebounce} from "use-debounce";
import {useForm} from "react-hook-form";
import {AddProductModal, shopifyBody, shopifyHead} from "./fragments";
import {shopifyActions} from "store";
import {AddInviteButton, InputMain} from "components";
import {Images} from "assets/images";
import {ShopifyMap} from "./fragments/shopifyMap";
import {httpRequestsOnLoadActions} from "../../store/http_requests_on_load";
import {SavePage, useModal} from "utils";

export const ShopifyMapPage = () => {
    const [search, setSearch] = useState(null);
    const [searchValue] = useDebounce(search, 500);
    const {shopifyProducts} = useSelector((state) => ({
        shopifyProducts: state.shopify.shopifyProducts,
    }));
    const dispatch = useDispatch();
    const history = useHistory();
    const {control, watch} = useForm();
    const {open} = useModal();
    const searchErply = watch('search')
    const [erplyCode] = useDebounce(searchErply, 500);
    const info = history?.location?.state;

    useEffect(() => {
        const loader = searchValue?.value ? 'noLoad' : 'loading'

        const filteredInfo = {
            ...info,
        };
        delete filteredInfo.page;
        dispatch(shopifyActions.getShopify({...filteredInfo, erplyCode, ...searchValue}, loader, searchValue?.searchKey));
    }, [erplyCode, searchValue, info])

    const renderTableValues = {
            head: shopifyHead,
            body: shopifyBody,
            actionType: 'GET_SHOPIFY',
            list: shopifyProducts?.mappings,
            count: shopifyProducts?.count,
    };

    const handleSearchReq = (value, name) => {
        SavePage(history);

        setSearch({
            [name]: value,
        });
    };

    const renderParams = () => {
        const filteredInfo = {
            ...info,
        };
        delete filteredInfo.page;
        delete filteredInfo.tab;

        if (searchValue?.value) {
            filteredInfo[searchValue?.searchKey] = searchValue?.value;
        }
        if (!searchValue?.value) {
            dispatch(httpRequestsOnLoadActions.removeLoading(searchValue?.searchKey));
            delete filteredInfo[searchValue?.searchKey];
        }
        return filteredInfo;
    };


    return (
        <div>
            <div className='shopify-map-input-button-wrapper'>
                <InputMain
                    className='search-erply-input'
                    placeholder='Search by Erply Code...'
                    name='search'
                    control={control}
                    showError={false}
                    type={'search'}
                    startAdornment={<Images.SearchNormalSvg/>}
                />
                <AddInviteButton text={'Add Product Code'} handleClick={() => open(<AddProductModal/>)}/>
            </div>

            <ShopifyMap
                renderParams={renderParams}
                head={renderTableValues?.head}
                body={renderTableValues?.body}
                loadingType={renderTableValues?.actionType}
                type={'map'}
                list={renderTableValues?.list}
                listCount={renderTableValues?.count}
                handleSearchReq={handleSearchReq}
            />
        </div>
    );
};

