import {
  addShopify,
  addShopifyProduct,
  deleteShopifyProduct,
  editShopifyProduct,
  getShopify,
  getShopifySetup,
  createShopifySetup,
  changeShopifySetup,
  syncResync,
  orderProductMap,
  getShopifyOrders,
  getShopifyTransverse, getShopifySingleSetup, changeShopifySetupStatus,
} from './shopify.action';


export { shopifyReducer } from './shopify.reducer';
export { watchShopify } from './shopify.saga';

export const shopifyActions = {
  /** Create, Edit Shopify Product */
  addShopify,
  addShopifyProduct,
  editShopifyProduct,
  /** End */

  /** Get Shopify */
  getShopify,
  getShopifySingleSetup,
  /** End */

  /** Delete Shopify Product */
  deleteShopifyProduct,
  /** End */

  /** Shopify Setup */
  getShopifySetup,
  createShopifySetup,
  changeShopifySetup,
  changeShopifySetupStatus,
  /** End */

  /** Shopify Orders */
  getShopifyOrders,
  getShopifyTransverse,
  syncResync,
  orderProductMap,
  /** End */
}
