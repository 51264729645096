export const RoutersInfo = [
    {
        name: 'Users',
        icon: 'Users',
        path: '/users',
    },
    {
        name: 'Erply',
        icon: 'Erply',
        path: '/erply',
    },
    {
        name: 'Amazon',
        icon: 'Amazon',
        path: '/amazon',
    },
    // {
    //     "name": "Walmart",
    //     "icon": "Walmart",
    //     "path": "/walmart",
    // },
    // {
    //     "name": "Ebay",
    //     "icon": "Ebay",
    //     "path": "/ebay",
    // },
    {
        name: 'Carecraft',
        icon: 'Carecraft',
        path: '/carecraft',
    },
    {
        name: 'Shopify',
        icon: 'Shopify',
        path: '/shopify/stores',
        notNavigate: true,
        includePaths: ['/shopify/product-map', '/shopify/inventory-transfers', '/shopify/errors'],
        subMenu: [
            {
                parent: 'Shopify',
                name: 'Stores',
                path: '/shopify/stores',
            },

            {
                parent: 'Shopify',
                name: 'Product Map',
                path: '/shopify/product-map',
            },

            {
                parent: 'Shopify',
                name: 'Inventory Transfers',
                path: '/shopify/inventory-transfers',
            },

            {
                parent: 'Shopify',
                name: 'Errors',
                path: '/shopify/errors',
            },
        ]
    },
];


