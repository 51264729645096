import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Router, RoutersInfo } from 'root';
import { ToastFail, ToastSuccess } from 'hooks';
import { httpRequestsOnSuccessActions } from 'store';
import { Colors } from 'utils';
import { Breadcrumb, Toast } from 'components';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { LeftBarImages, UserInfo } from './core';
import { Images } from '../../assets/images';
import { Collapse } from '@mui/material';

const drawerWidth = 200;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}));

export const MenuBar = () => {
    /**
     * Menu Bar.
     */

    const { httpOnError, httpOnSuccess, setUpSingle } = useSelector((state) => ({
        httpOnError: state.httpOnError,
        httpOnSuccess: state.httpOnSuccess,
        setUpSingle: state.shopify.setUpSingle,
    }));


    const [open, setOpen] = useState(false);
    const [openSubMenu, setOpenSubMenu] = useState(false);
    const dispatch = useDispatch();
    const navigate = useHistory();
    const [itemInfo, setItemInfo] = useState({
        path: '',
    });
    const info = navigate?.location?.state;

    useEffect(() => {
        setItemInfo({ path: window.location.pathname });
    }, []);

    const handleDrawerOpenClose = () => {
        setOpen(!open);
    };

    const handleNavigate = (item) => {
        if (!item?.notNavigate) {
            if (!item?.subMenu && !item?.parent) {
                setOpenSubMenu(false);
            } else if (item?.name === itemInfo?.name && item?.subMenu?.length > 0) {
                setOpenSubMenu(prevState => !prevState);
            } else {
                setOpenSubMenu(true);
            }

            setItemInfo(item);
            navigate.push(item?.path);
        } else {
            setOpenSubMenu(prevState => !prevState);
            if (!open) setOpen(true);
        }
    };

    const success = httpOnSuccess?.length && httpOnSuccess[0].type;
    const error = httpOnError?.length && httpOnError[0].type;
    const toastSuccess = ToastSuccess(success);
    const toastFail = ToastFail(error);

    useEffect(() => {
        if (toastSuccess) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(success));
        }
    }, [toastSuccess]);

    useEffect(() => {
        if (toastFail) {
            // dispatch(httpRequestsOnErrorsActions.removeError( error ))
        }
    }, [toastFail]);

    const bredCrumbs = (path) => {
        let breadCrumb = [];
        RoutersInfo.forEach((i) => {
            if (i.path === path && !i.subMenu) {
                breadCrumb.push({ title: i.name });
            } else if (i.subMenu) {
                i?.subMenu.forEach((sub) => {
                    if (sub.path === path) {
                        breadCrumb.push({ title: sub.name });
                    }
                });
            }
        });

        if (info?.singleStore) {
            breadCrumb = [{ title: 'Store Orders', to: '/shopify/stores' }, { title: info?.singleStore?.name }];
        }
        return breadCrumb;
    };

    return (
        <Box className="menu-bar-wrapper" sx={{ display: 'flex' }}>
            <CssBaseline />
            <div className="header-wrapper">
                <div className="header-logo-breadcrumb"
                     style={{ gap: open ? 150 : 20 }}
                >
                    <p className="logo">Starfish</p>
                    <Breadcrumb list={bredCrumbs(itemInfo?.path)} />
                </div>
                <UserInfo />
            </div>
            <Drawer variant="permanent" open={open}>
                <DrawerHeader>
                    <div>
                        <IconButton className="icon-button" onClick={handleDrawerOpenClose}>
                            {open === false ? <ChevronRight /> : <ChevronLeft />}
                        </IconButton>
                    </div>
                </DrawerHeader>

                <List className="list-style">
                    {RoutersInfo.map((i, k) => (
                        <div
                            className="list-style-box"
                            key={k}
                        >
                            <ListItemButton
                                onClick={() => handleNavigate(i)}
                                className={itemInfo?.path === i.path || i?.includePaths?.includes(itemInfo?.path) ? 'items-wrapper-active' : 'items-wrapper'}
                                sx={{
                                    px: 2.5,
                                    background: itemInfo?.path === i.path ? '#F4E5F3 0% 0% no-repeat padding-box' : 'transparent',
                                }}>

                                <ListItemIcon className="list-item-icon" sx={{ mr: open ? '8px' : '2px' }}>
                                    <LeftBarImages item={i} linkInfo={itemInfo?.path} />
                                </ListItemIcon>

                                <ListItemText
                                    className={'list-item-text'}
                                    style={{ color: itemInfo?.path === i.path || i?.includePaths?.includes(itemInfo?.path) ? Colors.main : Colors.placeHolder }}
                                    primary={i.name}
                                    sx={{ opacity: open ? 1 : 0 }}
                                />
                                {i?.subMenu && i?.subMenu?.length > 0 &&
                                    <Images.ArrowDownMenu
                                        style={{
                                            transform: openSubMenu ? 'rotate(0deg)' : 'rotate(180deg)',
                                            transition: 'transform 0.2s',
                                        }}
                                        className={`list-item-sub-arrow ${itemInfo?.path === i.path || i?.includePaths?.includes(itemInfo?.path) ? 'list-item-sub-arrow_active' : ''}`} />}
                            </ListItemButton>

                            {open && openSubMenu && i.subMenu &&
                                <div className="sub-list-style-wrapper">
                                    {i.subMenu.map((sub, index) => (
                                        <button
                                            key={index}
                                            className={`sub-list-item-style ${itemInfo?.path === sub?.path ? 'sub-list-item-style-active' : ''}`}
                                            onClick={() => handleNavigate(sub)}
                                            style={{ color: itemInfo?.path === sub?.path ? Colors.main : Colors.placeHolder }}
                                        >
                                            {sub?.name}
                                        </button>
                                    ))}
                                </div>
                            }
                        </div>

                    ))}
                </List>

                {open &&
                    <div className="create-by">
                        <img src={Images.CreatedBy} alt={'icon'} />
                    </div>
                }
            </Drawer>

            <Box className="body-wrapper" component="main" sx={{ flexGrow: 1, p: 3 }}>
                <DrawerHeader />
                <Router />
            </Box>

            <Toast
                type={toastSuccess ? 'success' : toastFail ? 'error' : ''}
                text={toastSuccess ? toastSuccess : toastFail ? toastFail : ''}
                info={toastSuccess ? !!toastSuccess : toastFail ? !!toastFail : ''}
            />
        </Box>
    );
};
