import React, {useEffect, useState} from 'react';
import {AddModalButton, CloseButton, ErrMessage} from "components";
import {useModal} from "utils";
import {Controller, useForm} from "react-hook-form";
import {useDispatch} from "react-redux";
import {Box, FormControlLabel, TextField, Switch} from "@mui/material";
import {AUTHENTICATION_TEXT_FIELD_STYLES_SMALL} from "fragments/modals/constants";
import {FindError, FindSuccess} from "hooks";
import {httpRequestsOnSuccessActions, shopifyActions} from "store";
import {shopifyStoresStatus} from "./constants";


export const AddStoreM = ({item, handleClose}) => {
    const ACTION_TYPE = item ? 'PATCH_SHOPIFY_SETUP' : 'POST_SHOPIFY_SETUP'
    const [checkedSwitch, setCheckedSwitch] = useState(false);
    const dispatch = useDispatch();
    const {close} = useModal();
    const success = FindSuccess(ACTION_TYPE);
    const backError = FindError(ACTION_TYPE)
    const {handleSubmit, control, register, reset} = useForm({
        mode: 'onBlur',
        defaultValues: {
            name: item?.name || '',
            accessToken: item?.accessToken || '',
            locationId: item?.locationId || '',
            shopUrl: item?.shopUrl || '',
            erplyWarehouse: item?.erplyWarehouse || '',
            erplyCustomer: item?.erplyCustomer || '',
        },
    });

    useEffect(() => {
        if (success) {
            close();
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE))
        }

    }, [success])


    useEffect(() => {
        if (item) {
            reset({...item})
            if (item?.status === shopifyStoresStatus.ACTIVE) {
                setCheckedSwitch(true)
            } else {
                setCheckedSwitch(false)
            }
        }

    }, [item])


    const onSubmit = (data) => {
        if (item) {
            dispatch(shopifyActions.changeShopifySetup(data, item?.id));

        } else {
            dispatch(shopifyActions.createShopifySetup(data));
        }
    }

    const handleChange = (e) => {
        setCheckedSwitch(e?.target?.checked);
        dispatch(shopifyActions.changeShopifySetupStatus(item?.id, e?.target?.checked ? shopifyStoresStatus.ACTIVE : shopifyStoresStatus.INACTIVE))
    };

    return (
        <div className='add-modal-fragment-2'>
            {!item ?
                <div className='modal-header'>
                    <CloseButton handleClose={close}/>
                </div>
                : <div className='drawer-settings-order'>
                    <p className='drawer-settings-order-title'>Settings</p>
                    <CloseButton handleClose={handleClose}/>
                </div>
            }

            <div className='modal-body-2'>
                {!item && <p className='add-modal-title' style={{marginBottom: '32px'}}>Add Store</p>}

                <form onSubmit={handleSubmit(onSubmit)} className='add-edit-store-form'>
                    <div>
                        {item && (
                            <div className='credentials-swicher'>
                                <FormControlLabel
                                    value='cronEnabled'
                                    name='cronEnabled'
                                    control={
                                        <Switch
                                            checked={checkedSwitch}
                                            onChange={handleChange}
                                            inputProps={{'aria-label': 'controlled'}}
                                            className='switch-colors'
                                            color={'secondary'}
                                        />
                                    }
                                    label='Active'
                                    labelPlacement='start'
                                />
                            </div>
                        )}

                        <Controller
                            name={'name'}
                            control={control}
                            render={({field: {onChange, value}}) => (
                                <TextField
                                    {...register('companyId', {require: true})}
                                    InputLabelProps={{shrink: value ? true : ''}}
                                    required={true}
                                    onChange={onChange}
                                    value={value}
                                    label={'Name'}
                                    size='medium'
                                    sx={AUTHENTICATION_TEXT_FIELD_STYLES_SMALL}
                                />
                            )}
                        />

                        <Controller
                            name={'accessToken'}
                            control={control}
                            render={({field: {onChange, value}}) => (
                                <TextField
                                    {...register('accessToken', {require: true})}
                                    InputLabelProps={{shrink: value ? true : ''}}
                                    required={true}
                                    onChange={onChange}
                                    value={value}
                                    label={'Access Token'}
                                    size='medium'
                                    sx={AUTHENTICATION_TEXT_FIELD_STYLES_SMALL}
                                />
                            )}
                        />

                        <Controller
                            name={'locationId'}
                            control={control}
                            render={({field: {onChange, value}}) => (
                                <TextField
                                    {...register('locationId', {require: true})}
                                    InputLabelProps={{shrink: value ? true : ''}}
                                    required={true}
                                    onChange={onChange}
                                    value={value}
                                    label={'Location ID'}
                                    size='medium'
                                    sx={AUTHENTICATION_TEXT_FIELD_STYLES_SMALL}
                                />
                            )}
                        />

                        <Controller
                            name={'shopUrl'}
                            control={control}
                            render={({field: {onChange, value}}) => (
                                <TextField
                                    {...register('shopUrl', {require: true})}
                                    InputLabelProps={{shrink: value ? true : ''}}
                                    required={true}
                                    onChange={onChange}
                                    value={value}
                                    label={'Shop URL'}
                                    size='medium'
                                    sx={AUTHENTICATION_TEXT_FIELD_STYLES_SMALL}
                                />
                            )}
                        />

                        <Controller
                            name={'erplyWarehouse'}
                            control={control}
                            render={({field: {onChange, value}}) => (
                                <TextField
                                    {...register('erplyWarehouse', {require: true})}
                                    InputLabelProps={{shrink: value ? true : ''}}
                                    required={true}
                                    onChange={onChange}
                                    value={value}
                                    label={'Erply Warehouse ID'}
                                    size='medium'
                                    sx={AUTHENTICATION_TEXT_FIELD_STYLES_SMALL}
                                />
                            )}
                        />

                        <Controller
                            name={'erplyCustomer'}
                            control={control}
                            render={({field: {onChange, value}}) => (
                                <TextField
                                    {...register('erplyCustomer', {require: true})}
                                    InputLabelProps={{shrink: value ? true : ''}}
                                    required={true}
                                    onChange={onChange}
                                    value={value}
                                    label={'Erply Customer'}
                                    size='medium'
                                    sx={AUTHENTICATION_TEXT_FIELD_STYLES_SMALL}
                                />
                            )}
                        />
                        <Box>{backError?.error && <ErrMessage text={backError.error}/>}</Box>
                    </div>

                    <div className='list-buttons-wrapper'>
                        <button
                            style={{width: '100%'}} className='chancel-button' onClick={() => {
                            close();
                            handleClose &&  handleClose();
                        }}
                            type={'button'}
                        >
                            Cancel
                        </button>

                        <AddModalButton
                            style={{width: '100%'}}
                            text={item ? 'Save' : 'Add'}
                            type='submit'
                            load={ACTION_TYPE}
                        />
                    </div>
                </form>
            </div>
        </div>
    );
};

