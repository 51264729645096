import React from "react";

export const CopyRight = ({}) => {
    const d = new Date();
    let year = d.getFullYear();

    return (
        <p className='copy-right'>
            Copyright © {year} Starfish. All Rights Reserved.
        </p>
    );
};
