import {GET_LOGS_SKU_SUCCESS} from './logs.type';

const initialState = {
    allLogs: [],
};


export const logsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_LOGS_SKU_SUCCESS:
            return {
                ...state,
                allLogs: action.payload,
            };

        default:
            return state;
    }
};
