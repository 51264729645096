import {
    ADD_SHOPIFY_PRODUCT,
    CREATE_SHOPIFY,
    DELETE_SHOPIFY_PRODUCT,
    EDIT_SHOPIFY_PRODUCT,
    GET_SHOPIFY, GET_SHOPIFY_ORDERS,
    GET_SHOPIFY_SETUP,
    PATCH_SHOPIFY_SETUP,
    POST_SHOPIFY_SETUP,
    GET_SHOPIFY_TRANSVERSE,
    GET_ORDER_PRODUCT_MAP_SHOPIFY,
    SYNC_RESYNC_SHOPIFY, GET_SHOPIFY_SINGLE_SETUP, EDIT_SHOPIFY_SETUP_STATUS,
} from './shopify.types';

/** Create, Edit SHOPIFY Product */

export const addShopify = (body) => {
    return {
        type: CREATE_SHOPIFY,
        payload: {body}
    }
}

export const addShopifyProduct = (body) => {
    return {
        type: ADD_SHOPIFY_PRODUCT,
        payload: {body}
    }
}

export const editShopifyProduct = (body, id,) => {
    return {
        type: EDIT_SHOPIFY_PRODUCT,
        payload: {body, id}
    }
}

export const getShopify = (params, load, searchType) => {
    return {
        type: GET_SHOPIFY,
        payload: {params, load, searchType},
    }
}

export const deleteShopifyProduct = (id) => {
    return {
        type: DELETE_SHOPIFY_PRODUCT,
        payload: {id}
    }
}

/** End */


/** Shopify Setup  */

export const getShopifySetup = (params) => {
    return {
        type: GET_SHOPIFY_SETUP,
        payload: {params}
    }
}

export const getShopifySingleSetup = (id) => {
    return {
        type: GET_SHOPIFY_SINGLE_SETUP,
        payload: {id}
    }
}

export const changeShopifySetup = (body, id) => {
    return {
        type: PATCH_SHOPIFY_SETUP,
        payload: {body, id}
    }
}

export const changeShopifySetupStatus = (storeId, status) => {
    return {
        type: EDIT_SHOPIFY_SETUP_STATUS,
        payload: {storeId, status}
    }
}

export const createShopifySetup = (body) => {
    return {
        type: POST_SHOPIFY_SETUP,
        payload: {body}
    }
}

/** End */

/** Shopify Orders  */

export const getShopifyOrders = (params) => {
    return {
        type: GET_SHOPIFY_ORDERS,
        payload: {params}
    }
}

export const getShopifyTransverse = (params) => {
    return {
        type: GET_SHOPIFY_TRANSVERSE,
        payload: {params}
    }
}

export const syncResync = (params, orderId, requestParams) => {
    return {
        type: SYNC_RESYNC_SHOPIFY,
        payload: {params, orderId, requestParams}
    }
}
export const orderProductMap = (id) => {
    return {
        type: GET_ORDER_PRODUCT_MAP_SHOPIFY,
        payload: {id}
    }
}

/** End */