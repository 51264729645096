import React, {useEffect} from 'react';
import {ShopifyMap} from "./fragments/shopifyMap";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {
    shopifyInventoryTransferBody,
    shopifyInventoryTransferHead,
} from "./fragments";
import {shopifyActions} from "store";

export const ShopifyInventoryTransfers = () => {

    const {shopifyTransverse} = useSelector((state) => ({
        shopifyTransverse: state.shopify.shopifyTransverse,
    }));
    const dispatch = useDispatch();
    const history = useHistory();
    const info = history?.location?.state;


    useEffect(() => {
        const filteredInfo = {
            ...info,
        };
        delete filteredInfo.page;
        delete filteredInfo.tab;

        dispatch(shopifyActions.getShopifyTransverse(filteredInfo))
    }, [])


    const renderTableValues = {
        head: shopifyInventoryTransferHead,
        body: shopifyInventoryTransferBody,
        actionType: 'GET_SHOPIFY_TRANSVERSE',
        list: shopifyTransverse?.inventoryTransfer,
        count: shopifyTransverse?.count,
    }


    return (
        <ShopifyMap
            // renderParams={renderParams}
            // handleOpen={handleOpenProductMap}
            head={renderTableValues?.head}
            body={renderTableValues?.body}
            loadingType={renderTableValues?.actionType}
            type={'transfer'}
            list={renderTableValues?.list}
            listCount={renderTableValues?.count}
            // handleSearchReq={handleSearchReq}
        />
    );
};

