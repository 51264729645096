import React, {useCallback, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import moment from 'moment';
import {Paper, Table, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import {FindLoad} from 'hooks';
import {SendPageSave, SavePage} from 'utils';
import {SearchAndFilter, PaginationItem, Loader, TableNoInfo} from 'components';
import {tableStyle} from './styles';

export const FullTable = ({
                              head,
                              body,
                              list,
                              loadingType,
                              handleClick,
                              noText,
                              margin,
                              status,
                              listCount,
                              handleClickButton
                          }) => {
    const classes = tableStyle();
    const [page, setPage] = useState(1);
    const history = useHistory();
    const info = history?.location?.state;
    const [selectedName, setSelectedName] = useState('');
    // const [selectedFilter, setSelectedFilter] = useState({});
    const loader = FindLoad(loadingType ? loadingType : '');
    const [listInfo, setListInfo] = useState('')
    const [currentWidth, setCurrentWidth] = useState(0);

    const curr = useCallback(node => {
        if (node !== null) {
            setCurrentWidth(node.getBoundingClientRect().width);
        }
    }, []);

    useEffect(() => {
        if (list) {
            setListInfo(list)
        }
    }, [list])

    useEffect(() => {
        const anotherInfo = {...info,}
        info?.status ? anotherInfo.status = info.status : status ? anotherInfo.status = status : delete anotherInfo.status
        SavePage(history, info, {...anotherInfo})
    }, []);

    useEffect(() => {
        if (info) {
            info?.page !== page && setPage(info?.page);
            // eslint-disable-next-line no-unused-expressions
            info?.sortType ? setSelectedName(info?.sortType) : '';
        }
    }, [info]);


    const handle = (e) => {
        if (e.target.value === 'All') {
            const cleanList = {
                ...info
            }
            delete cleanList[e.target.name]
            SavePage(history, info, {...cleanList})
        } else {
            if (!e.target.value) {
                const filteredList = {
                    ...info
                }
                delete filteredList[e.target.name]
                SavePage(history, info, {...filteredList})
            } else {
                const newObj = {
                    ...info
                }
                newObj[e.target.name] = e.target.value
                SavePage(history, info, {...newObj})
            }
        }
    }

    const setName = (name) => {
        const anotherInfo = {...info}
        if (name) {
            anotherInfo.sortType = name
        } else {
            delete anotherInfo.sortType
        }
        SavePage(history, info, {...anotherInfo})
    }

    const changePage = (number) => {
        setPage(number);
        SendPageSave(number, info, history);
    };

    const handleChange = (e, type, nameRow) => {
        if (type !== 'notChange') {
            const info = list.filter((el) =>
                type === 'date' ?
                    el[e.target.name] && moment(el[e.target.name]).format('DD/MM/YYYY').toString().toLowerCase().indexOf(e.target.value.toLowerCase()) > -1
                    :
                    el[e.target.name] && el[e.target.name].toString().toLowerCase().indexOf(e.target.value.toString().toLowerCase()) > -1
            )
            setListInfo(info)
        }
    }

    const handleName = (item, name) => {
        handleClickButton(item, name);
    };

    const renderItems = (i, item) => {
        if (item) {
            if (item?.button) {
                return item?.button((name) => handleName(i, name), i);
            } else {
                return item?.rowText(i, currentWidth);
            }
        }
    };

    if (loader?.length) return <Loader/>;

    return (
        <div style={{width: '100%'}}>
            <div style={margin ? {margin: 0} : {}} className={classes.tableWrapper}>
                <TableContainer className={classes.tableContainer} component={Paper}>
                    <Table stickyHeader aria-label='sticky table'>

                        <TableHead className={classes.tableHead}>
                            <TableRow>
                                {head ? head.map((i, j) => (
                                    <TableCell key={j} style={{ width: i?.headerCellWidth}}>
                                        <SearchAndFilter
                                            setSelectedFilter={handle}
                                            // selectedFilter={selectedFilter}

                                            // select={i?.filterList}
                                            selectedName={selectedName}
                                            setSelectedName={setName}
                                            // setSelectedName={setSelectedName}
                                            handleSearch={handleChange}
                                            name={i.name}
                                            width={i.width}
                                            itemKey={i.key}
                                            title={i.title}
                                            custom={i.custom}
                                            top={i.top}
                                            dateType={i?.type}
                                            notSearch={i.notSearch}
                                            smallSize={i?.smallSize}
                                            bigSize={i?.bigSize}
                                            iconType={i?.icon}
                                            nameRow={i?.nameRow}
                                        />
                                    </TableCell>
                                )) : ''}
                            </TableRow>
                        </TableHead>

                        <tbody>
                        {listInfo?.length ? listInfo?.map((i, j) => (
                                <React.Fragment key={j}>
                                    <TableRow className={classes.tableRow}>
                                        {body?.map((item, ke) => {
                                            return (
                                                <TableCell
                                                    ref={curr}
                                                    onClick={() => item?.notClickable !== true && handleClick && handleClick(i)}
                                                    style={item.type === 'returnColor' ?
                                                        {
                                                            // background: handleReturnColor(i?.billing?.billedDate, i?.customer?.pmtTerm),
                                                            textAlign: 'center',
                                                            color: '#51566D',
                                                            width: i?.width
                                                        }
                                                        :
                                                        {color: '#51566D', width: item?.width}
                                                    }
                                                    key={ke}>{renderItems(i, item, currentWidth) || 'Not Set'}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                </React.Fragment>
                            ))
                            :
                            <TableNoInfo text={`No ${noText} Yet`}/>
                        }
                        </tbody>
                    </Table>

                    {listCount > 0 ?
                        <PaginationItem
                            listLength={list?.length}
                            page={page}
                            handleReturn={(number) => changePage(number)}
                            count={listCount}
                        />
                        : ''
                    }
                </TableContainer>
            </div>
        </div>
    );
};