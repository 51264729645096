import {
    getLogsSku,
    resolveLogsSku
} from './logs.action';

export {logsReducer} from './logs.reducer';
export {watchLogs} from './logs.saga';

export const logsActions = {
    getLogsSku,
    resolveLogsSku
};
