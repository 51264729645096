import {NavLink} from 'react-router-dom';
import {Images} from 'assets/images';

export const Breadcrumb = ({list, separator, className}) => {
    return (
        <div className={`breadcrumb-wrapper ${className ? className : ''}`}>
            {list?.map((i, j) =>
                i?.to ? (
                    <NavLink key={j} to={i?.to}>
                        {i?.title} {separator || <Images.BreadcrumbSvg/>}
                    </NavLink>
                ) : (
                    <p key={j}>{i?.title}</p>
                )
            )}
        </div>
    );
};
