import {
    GET_LOGS_SKU, RESOLVE_SINGLE_LOGS_SKU

} from './logs.type';

export const getLogsSku = (data) => {
    return {
        type: GET_LOGS_SKU,
        payload: data,
    };
};

export const resolveLogsSku = (data, id) => {
    return {
        type: RESOLVE_SINGLE_LOGS_SKU,
        payload: {
            data,
            id
        },
    };
};
