import React from 'react';
import {DateRow, NameRow, TextToolTip} from 'components';
import {Images} from 'assets/images';
import {ReactComponent as ProblemSolvingSvg} from "../../../assets/images/svg/ProblemSolvingSvg.svg";

const renderButtons = (data, info, load) => {
    return <div className="del-edit-wrapper">
        <button onClick={() => data('edit')}>
            <img src={Images.Edit} alt={'icon'}/>
            <p className="edit-text">Edit</p>
        </button>
        <button onClick={() => data('delete')}>
            <img src={Images.Remove} alt={'icon'}/>
            <p className="delete-text">Delete</p>
        </button>
    </div>;
};

export const shopifyHead = [
    {name: 'searchRequest', nameRow: 'sku', title: 'Shopify SKU', custom: false},
    // { name: 'sku', title: 'Shopify SKU' },
    {name: 'productType', title: 'Type', custom: false},
    {name: 'actions', title: 'Actions', custom: false, type: 'notChange'},
];

export const shopifyBody = [
    // { rowText: (item) => <NameRow name={item?.sku} />, notClickable: true },
    // { rowText: (item) => <TextRow  big={true} size={20} name={'item?.erplyCode'} />, notClickable: true },
    // { rowText: (item) => <NameRow name={item?.multiplier} />, notClickable: true },
    // { rowText: (item) => <NameRow name={item?.productType ? item?.productType : 'Not Set'} />, notClickable: true },
    // { button: (item, info, load) => renderButtons(item, info, load), notClickable: true },
];


export const shopifyOrderHead = [
    {name: 'searchRequest', nameRow: 'orderId', title: ' Shopify Order ID', custom: false},
    {name: 'addedDate', title: 'Added Date,Time', custom: false},
    {name: 'createdAt', title: 'Created Date', custom: false},
    {name: 'Status', title: 'Status', custom: false},
    {name: 'actions', title: 'Actions', custom: false, type: 'notChange'},
];

export const shopifyOrderBody = [
    {rowText: (item) => <NameRow name={item?.sku}/>, notClickable: true},
    {rowText: (item) => <NameRow big={true} size={20} name={item?.erplyCode}/>, notClickable: true},
    {rowText: (item) => <NameRow name={item?.multiplier}/>, notClickable: true},
    {rowText: (item) => <NameRow name={item?.productType ? item?.productType : 'Not Set'}/>, notClickable: true},
    {button: (item, info, load) => renderButtons(item, info, load), notClickable: true},
];


export const shopifyInventoryTransferHead = [
    {name: 'transferId', title: 'Transfer ID', custom: false},
    {name: 'transferDate', title: 'Transfer Date', custom: false},
    {name: 'warehouseFrom', title: 'Warehouse From', custom: false},
    {name: 'warehouseTo', title: 'Warehouse To', custom: false},
    {name: 'qty', title: 'Qty', custom: false},
    {name: 'status', title: 'Status', custom: false},
];

export const shopifyInventoryTransferBody = [
    {rowText: (item) => <NameRow name={item?.sku}/>, notClickable: true},
    {rowText: (item) => <NameRow big={true} size={20} name={item?.erplyCode}/>, notClickable: true},
    {rowText: (item) => <NameRow name={item?.multiplier}/>, notClickable: true},
    {rowText: (item) => <NameRow name={item?.productType ? item?.productType : 'Not Set'}/>, notClickable: true},
    {button: (item, info, load) => renderButtons(item, info, load), notClickable: true},
];


export const shopifyErrorsHead = [
    {name: 'store', title: ' Store Name', type: 'date', custom: false, headerCellWidth: '15%'},
    {name: 'createdAt', title: ' Date', type: 'date', custom: false, headerCellWidth: '15%'},
    {name: 'message', title: 'Message', custom: false, headerCellWidth: '55%'},
    {name: 'actions', title: 'Actions', custom: false, type: 'notChange', headerCellWidth: '15%'},
];

export const shopifyErrorsBody = (onClick, currentId) => {
    return [
        {
            rowText: (item) => {
                return <TextToolTip text={item?.storeSetup?.name} content={item?.storeSetup?.name}/>
            },
            width: '15%'
        },

        {rowText: (item) => <DateRow date={item?.createdAt}/>, width: '15%'},
        {
            rowText: (item) => <TextToolTip text={item?.message} content={item?.message}/>,
            notClickable: true,
            width: '55%'
        },
        {
            button: (item, info, load) => (
                <div style={{display: 'flex', alignItems: 'center', gap: '8px', width: '15%'}}
                     onClick={() => onClick(info)}>
                    <ProblemSolvingSvg/>
                    <p style={{
                        fontSize: '14',
                        fontWeight: '600',
                        textDecoration: 'underline',
                        color: '#BA43B1'
                    }}>
                        {currentId === info.id ? 'Resolving...' : 'Resolve'}
                    </p>
                </div>
            ),
        }
    ]
}

export const shopifyStoresStatus = {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
}


export const shopifyStoresHead = [
    {name: 'name', title: 'Store Name', custom: false, type: 'notChange', headerCellWidth: '40%'},
    {name: 'createdAt', title: 'Added Date', type: 'notChange', custom: false, headerCellWidth: '30%'},
    {name: 'Status', title: 'Status', type: 'notChange', custom: false, headerCellWidth: '30%'},
];


export const shopifyStoresBody = () => {
    return [
        {rowText: (item) => <TextToolTip text={item?.name} content={item?.name}/>, width: '40%'},
        {rowText: (item) => <DateRow date={item?.createdAt}/>, width: '30%'},
        {
            button: (item, info, load) => (
                <div style={{display: 'flex', alignItems: 'center', gap: '8px'}}>
                    {info?.status === shopifyStoresStatus.ACTIVE ?
                        <div
                            style={{
                                width: '119px',
                                color: '#0DA137',
                                fontFamily: "Open Sans",
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                lineHeight: '20px',

                                borderRadius: '4px',
                                background: '#E7F8EB',
                                padding: '4px 32px'
                            }}
                        >
                            Active
                        </div>

                        :
                        <div
                            style={{
                                width: '119px',
                                color: '#D72525',
                                fontFamily: "Open Sans",
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                lineHeight: '20px',

                                borderRadius: '4px',
                                background: '#FBF0F0',
                                padding: '4px 32px'
                            }}
                        >
                            Inactive
                        </div>
                    }

                </div>
            ),
            width: '30%'
        }
    ]
}


