import {call, put, takeLatest} from 'redux-saga/effects';
import {logsService} from './logs.service';
import {
    GET_LOGS_SKU,
    GET_LOGS_SKU_SUCCESS, RESOLVE_SINGLE_LOGS_SKU
} from './logs.type';
import {httpRequestsOnLoadActions} from '../http_requests_on_load';


/** CareCraft */
function* getCareCraft({payload, type}) {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    try {
        const res = yield call(logsService.getLogsSku, payload);
        yield put({
            type: GET_LOGS_SKU_SUCCESS,
            payload: res.data,
        });
        if (payload?.searchType) {
            yield put(httpRequestsOnLoadActions.removeLoading(payload?.searchType));
        }
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* resolveLogsSku({payload, type}) {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    try {
        yield call(logsService.resolveLogsSkuById, payload?.id);

        yield put({
            type: GET_LOGS_SKU,
            payload: payload.data,
        });
        if (payload?.searchType) {
            yield put(httpRequestsOnLoadActions.removeLoading(payload?.searchType));
        }
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}


export const watchLogs = function* watchCarecraftSaga() {
    yield takeLatest(GET_LOGS_SKU, getCareCraft);
    yield takeLatest(RESOLVE_SINGLE_LOGS_SKU, resolveLogsSku);
};
