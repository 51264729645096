
/** Create Amazon */
export const CREATE_AMAZON = 'CREATE_AMAZON';

/** Add, Edit Amazon product */
export const ADD_AMAZON_PRODUCT = 'ADD_AMAZON_PRODUCT'

export const EDIT_AMAZON_PRODUCT = 'EDIT_AMAZON_PRODUCT'

/** Get Amazon */
export const GET_AMAZON = 'GET_AMAZON';
export const GET_AMAZON_SUCCESS = 'GET_AMAZON_SUCCESS';

/** Delete Amazon Product */
export const DELETE_AMAZON_PRODUCT = 'DELETE_AMAZON_PRODUCT';
export const DELETE_AMAZON_PRODUCT_SUCCESS = 'DELETE_AMAZON_PRODUCT_SUCCESS';

/** Amazon Product Type */
export const GET_AMAZON_TYPE = 'GET_AMAZON_TYPE';
export const GET_AMAZON_TYPE_SUCCESS = 'GET_AMAZON_TYPE_SUCCESS';

export const EDIT_AMAZON_TYPE = 'EDIT_AMAZON_TYPE';
export const EDIT_AMAZON_TYPE_SUCCESS = 'EDIT_AMAZON_TYPE_SUCCESS';

export const ADD_AMAZON_TYPE = 'ADD_AMAZON_TYPE';
export const ADD_AMAZON_TYPE_SUCCESS = 'ADD_AMAZON_TYPE_SUCCESS';

export const DELETE_AMAZON_TYPE = 'DELETE_AMAZON_TYPE';
export const DELETE_AMAZON_TYPE_SUCCESS = 'DELETE_AMAZON_TYPE_SUCCESS';

/** Amazon Setup Endpoints */

export const GET_AMAZON_SETUP = 'GET_AMAZON_SETUP';
export const GET_AMAZON_SETUP_SUCCESS = 'GET_AMAZON_SETUP_SUCCESS';

export const PATCH_AMAZON_SETUP = 'PATCH_AMAZON_SETUP';
export const PATCH_AMAZON_SETUP_SUCCESS = 'PATCH_AMAZON_SETUP_SUCCESS';

export const POST_AMAZON_SETUP = 'POST_AMAZON_SETUP';
export const POST_AMAZON_SETUP_SUCCESS = 'POST_AMAZON_SETUP_SUCCESS';

/** Amazon Orders */

export const GET_AMAZON_ORDERS = 'GET_AMAZON_ORDERS'
export const GET_AMAZON_ORDERS_SUCCESS = 'GET_AMAZON_ORDERS_SUCCESS'

export const GET_AMAZON_TRANSVERS = 'GET_AMAZON_TRANSVERS'
export const GET_AMAZON_TRANSVERS_SUCCESS = 'GET_AMAZON_TRANSVERS_SUCCESS'
export const SYNC_RESYNC = 'SYNC_RESYNC'
export const GET_ORDER_PRODUCT_MAP = 'GET_ORDER_PRODUCT_MAP'
export const GET_ORDER_PRODUCT_MAP_SUCCESS = 'GET_ORDER_PRODUCT_MAP_SUCCESS'

