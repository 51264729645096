import { call, put, takeLatest } from 'redux-saga/effects';
import { authService } from './amazon.service';
import { httpRequestsOnLoadActions } from '../http_requests_on_load';
import {
    ADD_AMAZON_PRODUCT,
    ADD_AMAZON_TYPE,
    CREATE_AMAZON, CREATE_AMAZON_PRODUCT_SETTINGS_SUCCESS,
    DELETE_AMAZON_PRODUCT,
    DELETE_AMAZON_TYPE,
    EDIT_AMAZON_PRODUCT, EDIT_AMAZON_PRODUCT_SETTINGS_SUCCESS,
    EDIT_AMAZON_TYPE,
    GET_AMAZON,
    GET_AMAZON_ORDERS,
    GET_AMAZON_ORDERS_SUCCESS, GET_AMAZON_PRODUCT_SETTINGS,
    GET_AMAZON_SETUP,
    GET_AMAZON_SETUP_SUCCESS,
    GET_AMAZON_SUCCESS, GET_AMAZON_TRANSVERS, GET_AMAZON_TRANSVERS_SUCCESS,
    GET_AMAZON_TYPE,
    GET_AMAZON_TYPE_SUCCESS, GET_ORDER_PRODUCT_MAP, GET_ORDER_PRODUCT_MAP_SUCCESS,
    PATCH_AMAZON_SETUP,
    POST_AMAZON_SETUP, SYNC_RESYNC,
} from './amazon.types';
import { httpRequestsOnSuccessActions } from '../http_requests_on_success';
import { httpRequestsOnErrorsActions } from '../http_requests_on_errors';

/** Create, Edit Amazon Product */

function* createAmazon({ payload, type }) {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    try {
        const res = yield call(authService.createAmazonService, payload.body);
        yield put({
            type: GET_AMAZON_SUCCESS,
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
    }
}

function* addAmazonProduct({ payload, type }) {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(authService.createAmazonService, payload.body);
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    }
}

function* editAmazonProduct({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(authService.editAmazonMap, payload.body, payload.id);
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    }
}

function* deleteAmazonProduct({ payload, type }) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    try {
        yield call(authService.deleteAmazonMap, payload.id);
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    }
}

function* getAmazon({ type, payload }) {
    if (payload?.load !== 'noLoad') {
        yield put(httpRequestsOnLoadActions.appendLoading(type));
    }
    try {
        const res = yield call(authService.getAmazonService, payload?.params);
        yield put({
            type: GET_AMAZON_SUCCESS,
            payload: res.data,
        });
        if (payload?.searchType) {
            yield put(httpRequestsOnLoadActions.removeLoading(payload?.searchType));
        }
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

/** End */

/**  Create, Edite, Delete and Get Amazon Type */

function* getAmazonType({ type, payload }) {
    if (payload?.load !== 'noLoad') {
        yield put(httpRequestsOnLoadActions.appendLoading(type));
    }
    try {
        const res = yield call(authService.getAmazonType, payload.params);
        yield put({
            type: GET_AMAZON_TYPE_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (error) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* addAmazonType({ payload, type }) {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        const res = yield call(
            authService.addAmazoonType,
            payload.body,
        );
        yield put({
            type: GET_AMAZON_TYPE,
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    }
}

function* deleteAmazonType({ payload, type }) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    try {
        yield call(authService.deleteAmazonType, payload.id);
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (err) {
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* editAmazonType({ payload, type }) {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(authService.editAmazonType, payload.body, payload.id);
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    }
}

/** Amazon Setup Endpoinds */

function* addAmazonSeyup({ payload, type }) {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnErrorsActions.removeError(type))
    try {
        const res = yield call(
            authService.creatAmazonSetup,
            payload.body,
        );
        yield put({
            type: GET_AMAZON_SETUP_SUCCESS,
            payload: res.data
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnErrorsActions.appendError(type))
    }
}

function* changeAmazonSetup({ payload, type }) {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnErrorsActions.removeError(type))
    try {
        const res = yield call(
            authService.editAmazonSetup,
            payload.body,
        );
        yield put({
            type: GET_AMAZON_SETUP_SUCCESS,
            payload: res.data
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type))
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    }
}

function* getAmazonSetup({ type }) {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    try {
        const res = yield call(authService.getAmazonSetup);
        yield put({
            type: GET_AMAZON_SETUP_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (error) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

/** Amazon Orders */
function* getAmazonOrders({ type, payload }) {
    if(payload?.load !== 'load') {
        yield put(httpRequestsOnLoadActions.appendLoading(type));
    }
    try {
        const res = yield call(authService.getAmazonOrdersService, payload?.params);
        yield put({
            type: GET_AMAZON_ORDERS_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (error) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* getAmazonTransvers({ type, payload }) {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    try {
        const res = yield call(authService.getAmazonTransversService, payload?.params);
        yield put({
            type: GET_AMAZON_TRANSVERS_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (error) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* syncResync({ type, payload }) {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    try {
        yield call(authService.syncResyncService, payload?.params, payload?.orderId);
        yield put({
            type: GET_AMAZON_ORDERS,
            payload: {  params: payload?.requestParams, load: 'noLoad' },
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (error) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* orderProductMap({ type, payload }) {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    try {
        const res = yield call(authService.orderProductMapService, payload?.id);
        yield put({
            type: GET_ORDER_PRODUCT_MAP_SUCCESS,
            payload: res?.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (error) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

export const watchAmazon = function* watchAmazonSaga() {
    /** Amazon Product */
    yield takeLatest(CREATE_AMAZON, createAmazon);
    yield takeLatest(ADD_AMAZON_PRODUCT, addAmazonProduct);
    yield takeLatest(EDIT_AMAZON_PRODUCT, editAmazonProduct);
    yield takeLatest(DELETE_AMAZON_PRODUCT, deleteAmazonProduct);
    yield takeLatest(GET_AMAZON, getAmazon);

    /** Amazon Type  */
    yield takeLatest(GET_AMAZON_TYPE, getAmazonType);
    yield takeLatest(ADD_AMAZON_TYPE, addAmazonType);
    yield takeLatest(EDIT_AMAZON_TYPE, editAmazonType);
    yield takeLatest(DELETE_AMAZON_TYPE, deleteAmazonType);

    /** Amazon Setup */
    yield takeLatest(GET_AMAZON_SETUP, getAmazonSetup);
    yield takeLatest(POST_AMAZON_SETUP, addAmazonSeyup);
    yield takeLatest(PATCH_AMAZON_SETUP, changeAmazonSetup);

    /** Amazon Orders */
    yield takeLatest(GET_AMAZON_ORDERS, getAmazonOrders);
    yield takeLatest(GET_AMAZON_TRANSVERS, getAmazonTransvers);
    yield takeLatest(SYNC_RESYNC, syncResync);
    yield takeLatest(GET_ORDER_PRODUCT_MAP, orderProductMap);
};
