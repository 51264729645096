import React, {useEffect, useRef, useState} from "react";
import {Tooltip} from "@mui/material";

export const TextToolTip = (
    {
        text = "Not Set",
        content = "Not Set",
        lineClamp = 1,
        arrow = false,
        classNameText,
        textStyles,
        placement = 'top'
    }) => {
    const [dontShowTooltip, setShowingTooltip] = useState(true);
    const targetRef = useRef();

    const checkOverflow = () => {
        if (targetRef.current?.scrollHeight > targetRef.current?.clientHeight) {
            setShowingTooltip(false);
        } else {
            setShowingTooltip(true);
        }
    };

    useEffect(() => {
        window.addEventListener('resize', checkOverflow);

        return () => {
            window.removeEventListener('resize', checkOverflow);
        };
    }, []);


    return (
        <div className='text-tooltip-wrapper'>
            <Tooltip
                title={content}
                disableHoverListener={dontShowTooltip}
                arrow={arrow}
                placement={placement}
            >
                <div
                    id={"text-tooltip"}
                    className={`main-tooltip-text ${classNameText ? classNameText : ""}`}
                    style={{WebkitLineClamp: lineClamp, ...textStyles}}
                    ref={targetRef}
                >
                    {text}
                </div>
            </Tooltip>
        </div>
    );
};
