export const ToastSuccess = (success) => {
    if (success) {
        return success === 'PATCH_AMAZON_SETUP'
            ? 'Amazon setup was edited'
            : success === 'POST_AMAZON_SETUP'
                ? 'Amazon setup was created'
                : success === 'POST_SHOPIFY_SETUP'
                    ? 'Starfish store was created'
                    : success === 'PATCH_SHOPIFY_SETUP'
                        ? 'Starfish store was edited'
                        : success === 'CREATE_ERPLY'
                            ? 'Erply settings was created'
                            : success === 'EDIT_ERPLY'
                                ? 'Erply settings was edited'
                                : success === 'CREATE_WAREHOUSE'
                                    ? 'WareHouse was created'
                                    : success === 'EDIT_WAREHOUSE'
                                        ? 'WareHouse was edited'
                                        : success === 'DELETE_WAREHOUSE'
                                            ? 'WareHouse was deleted'
                                            : success === 'ADD_ERPLY_PRODUCT'
                                                ? 'Erply was created'
                                                : success === 'EDIT_ERPLY_PRODUCT'
                                                    ? 'Erply was edited'
                                                    : success === 'CREATE_ERPLY'
                                                        ? 'Erply was created'
                                                        : success === 'EDIT_ERPLY'
                                                            ? 'Erply was edited'
                                                            : success === 'CREATE_PO_NUMBER' ? 'Purchase order was created' :
                                                                success === 'CHANGE_CARECRAFT_PO'
                                                                    ? 'Purchase order was edited'
                                                                    : success === 'EDIT_CREDENTIAL'
                                                                        ? 'Credential was edited'
                                                                        : success === 'SEND_CARECRAFT_SUCCESS'
                                                                            ? 'Purchase order was edited'
                                                                            : success === 'DELETE_CARECRAFT_PO'
                                                                                ? 'Purchase order was deleted'
                                                                                : success === 'CREATE_CARECRAFT_SETUP'
                                                                                    ? 'CareCraft settings was edited'
                                                                                    : false;
    }
};
