import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {FindError, FindSuccess} from "hooks";
import {httpRequestsOnErrorsActions, httpRequestsOnSuccessActions, shopifyActions} from "store";
import {AddModalButton, CloseButton, ErrMessage, SettingsButton} from "components";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import {Box, FormControlLabel, Switch, TextField} from "@mui/material";
import {Controller, useForm} from "react-hook-form";
import {AUTHENTICATION_TEXT_FIELD_STYLES_SMALL} from "../../../fragments/modals/constants";
import {AddStoreM} from "./addStoreM";


export const RightDrawer = ({item}) => {
    const dispatch = useDispatch();
    const [state, setState] = useState({right: false});

    const ACTION_TYPE = item ? 'PATCH_SHOPIFY_SETUP' : 'POST_SHOPIFY_SETUP'
    const success = FindSuccess(ACTION_TYPE);



    useEffect(() => {
        if (success?.type === ACTION_TYPE) {
            setState({right: false});
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
        }
    }, [success]);

    useEffect(() => {
        dispatch(shopifyActions.getShopifySetup());
    }, []);


    const toggleDrawer = (anchor, open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({...state, [anchor]: open});
    };


    return (
        <Fragment key={'right'}>
            <SettingsButton text={'Settings'} handleClick={toggleDrawer('right', true)}/>
            <SwipeableDrawer
                anchor={'right'}
                open={state['right']}
                onClose={toggleDrawer('right', false)}
                onOpen={toggleDrawer('right', true)}
            >
             <AddStoreM item={item} handleClose={toggleDrawer('right', false)}/>
            </SwipeableDrawer>
        </Fragment>
    );
}
