import {DateRow, NameRow, TextToolTip} from 'components';
import React from 'react';
import {Images} from 'assets/images';
import {ReactComponent as ProblemSolvingSvg} from "../../../assets/images/svg/ProblemSolvingSvg.svg";

const renderButtons = (data, info, load) => {
    return <div className="del-edit-wrapper">
        <button onClick={() => data('edit')}>
            <img src={Images.Edit} alt={'icon'}/>
            <p className="edit-text">Edit</p>
        </button>
        <button onClick={() => data('delete')}>
            <img src={Images.Remove} alt={'icon'}/>
            <p className="delete-text">Delete</p>
        </button>
    </div>;
};

export const amazonHead = [
    {name: 'searchRequest', nameRow: 'sku', title: 'Amazon SKU', custom: false},
    // { name: 'sku', title: 'Amazon SKU' },
    {name: 'productType', title: 'Type', custom: false},
    {name: 'actions', title: 'Actions', custom: false, type: 'notChange'},
];
export const amazonBody = [
    // { rowText: (item) => <NameRow name={item?.sku} />, notClickable: true },
    // { rowText: (item) => <TextRow  big={true} size={20} name={'item?.erplyCode'} />, notClickable: true },
    // { rowText: (item) => <NameRow name={item?.multiplier} />, notClickable: true },
    // { rowText: (item) => <NameRow name={item?.productType ? item?.productType : 'Not Set'} />, notClickable: true },
    // { button: (item, info, load) => renderButtons(item, info, load), notClickable: true },
];


export const ACTION_ORDER_TYPE = 'GET_AMAZON';


export const amazonOrderHead = [
    {name: 'searchRequest', nameRow: 'orderId', title: ' Amazon Order ID', custom: false},
    {name: 'addedDate', title: 'Added Date,Time', custom: false},
    {name: 'createdAt', title: 'Created Date', custom: false},
    {name: 'Status', title: 'Status', custom: false},
    {name: 'actions', title: 'Actions', custom: false, type: 'notChange'},
];

export const amazonOrderBody = [
    {rowText: (item) => <NameRow name={item?.sku}/>, notClickable: true},
    {rowText: (item) => <NameRow big={true} size={20} name={item?.erplyCode}/>, notClickable: true},
    {rowText: (item) => <NameRow name={item?.multiplier}/>, notClickable: true},
    {rowText: (item) => <NameRow name={item?.productType ? item?.productType : 'Not Set'}/>, notClickable: true},
    {button: (item, info, load) => renderButtons(item, info, load), notClickable: true},
];


export const amazonInventoryTransferHead = [
    {name: 'transferId', title: 'Transfer ID', custom: false},
    {name: 'transferDate', title: 'Transfer Date', custom: false},
    {name: 'warehouseFrom', title: 'Warehouse From', custom: false},
    {name: 'warehouseTo', title: 'Warehouse To', custom: false},
    {name: 'qty', title: 'Qty', custom: false},
    {name: 'status', title: 'Status', custom: false},
];

export const amazonInventoryTransferBody = [
    {rowText: (item) => <NameRow name={item?.sku}/>, notClickable: true},
    {rowText: (item) => <NameRow big={true} size={20} name={item?.erplyCode}/>, notClickable: true},
    {rowText: (item) => <NameRow name={item?.multiplier}/>, notClickable: true},
    {rowText: (item) => <NameRow name={item?.productType ? item?.productType : 'Not Set'}/>, notClickable: true},
    {button: (item, info, load) => renderButtons(item, info, load), notClickable: true},
];

export const amazonErrorsHead = [
    {name: 'createdAt', title: ' Date', type: 'date', custom: true},
    {name: 'message', title: 'Message', custom: false},
    {name: 'actions', title: 'Actions', custom: false, type: 'notChange'},
];

export const amazonErrorsBody = (onClick) => {
    return [
        {rowText: (item) => <DateRow date={item?.createdAt}/>,  width:'15%'},
        {rowText: (item) => <TextToolTip text={item?.message} content={item?.message}/>, notClickable: true,  width:'70%'},
        {
            button: (item, info, load) => (
                <div style={{display: 'flex', alignItems: 'center', gap: '8px',  width:'15%'}} onClick={() => onClick(info)}>
                    <ProblemSolvingSvg/>
                    <p style={{
                        fontSize: '14',
                        fontWeight: '600',
                        textDecoration: 'underline',
                        color: '#BA43B1'
                    }}>Resolve</p>
                </div>
            ),
        }
    ]
}


