import axios from 'axios';

export const authService = {

    /** Create, Edit Amazon Product */

    createAmazonService: (body) => axios.post(`/map`, { ...body, type: 'AMAZON' }, { auth: true }),

    getAmazonService: (params) => axios.get(`/map`, { auth: true, params: { ...params, type: 'AMAZON' } }),

    editAmazonMap: (body, id) => axios.patch(`/map/${id}`, { ...body, type: 'AMAZON' }, { auth: true }),

    deleteAmazonMap: (id) => axios.delete(`/map/${id}`, { auth: true, params: { type: 'AMAZON' } }),

    /** End */

    /**  Creaet , Edit, Delete and Get Amazon Product  */

    addAmazoonType: (body) => axios.post(`/product-type`, body, { auth: true }),

    getAmazonType: (params) => axios.get(`/product-type`, { auth: true, params: { ...params } }),

    editAmazonType: (body, id) => axios.patch(`/product-type/${id}`, body, { auth: true }),

    deleteAmazonType: (id) => axios.delete(`/product-type/${id}`, { auth: true }),

    /** End */

    /**  Amazon Setup  */

    getAmazonSetup: () => axios.get(`/amazon/setup`, { auth: true }),

    editAmazonSetup: (body) => axios.patch(`/amazon/setup`, body, { auth: true }),

    creatAmazonSetup: (body) => axios.post(`/amazon/setup`, body, { auth: true }),

    /**  Amazon Orders  */

    getAmazonOrdersService: (params) => axios.get(`/amazon/orders`, { auth: true, params: params }),

    getAmazonTransversService: (params) => axios.get(`/transfer`, {
        auth: true,
        params: { ...params, type: 'AMAZON' },
    }),

    syncResyncService: (params, orderId) => axios.patch(`/core/amazon/orders/${orderId}`, null, {
        auth: true,
        params: params,
    }),

    orderProductMapService: (id) => axios.get(`/map/sku/${id}`, { auth: true, params: { type: 'AMAZON' } }),

    /** End */

};
