import React, { useRef } from 'react';

import moment from "moment";
import { SlicedText } from '../messagesP';
import { HtmlTooltip } from '../messages';

export const IconAndTitle = ({icon, title, size}) => {
    return (
        <div className='info-and-image'>
            <img src={icon} alt="Icon"/>
            <SlicedText type={'name'} size={size ? size : 10} data={title}/>
        </div>
    )
}

export const IdRow = ({id}) => {
    return (
        <SlicedText type={'id'} size={10} data={id}/>
    )
}

export const DateRow = ({date}) => {
    return (
        moment.utc(date).format('MM/DD/YYYY')
    )
}

export const NameRow = ({name, size, big}) => {
    return (
        <SlicedText type={'bigText'} size={20} data={name ? name : 'Not Set'}/>
    )
}

export const EmailRow = ({name, size}) => {
    return (
        <SlicedText type={'email'} size={size ? size : 20} data={name ? name : 'Not Set'}/>
    )
}

export const AddressRow = ({name, size}) => {
    return (
        <SlicedText type={'address'} size={size ? size : 30} data={name ? name : 'Not Set'}/>
    )
}


export const PriceRow = ({info}) => {
    return (
        `$${info ? info : 0}`
    )
}

export const PhoneRow = ({phone}) => {
    return (
        `+${phone}`
    )
}

export const TypeRow = ({text}) => {
    return (
        text.replace(/(\w)(\w*)/g, function (g0, g1, g2) {
            return g1.toUpperCase() + g2.toLowerCase();
        })
    )
}
export const TextRow = ({name, textWidth, width, color}) => {
    const ref = useRef()
    const text = (name ? name : 'Not Set') + ''
    const currentWidth = width ? width : ref?.current?.offsetWidth ? ref?.current?.offsetWidth : 200
    return (
        <div ref={ref}>{RenderText(currentWidth, text, textWidth, color)}</div>
    )
}

const RenderText = (width, text, textWidth,color) => {
    if (text) {

        const count = Math.ceil(width / (textWidth ? textWidth : 11))
        const slicedText = '' + text?.slice(0, count)
        return <div className='text-style' style={color ? {color:color}: {}}>
            {text?.length > count ?
                <HtmlTooltip
                    title={<p>{text}</p>}
                    placement="top-start"
                >
                    <p style={{textOverflow: 'ellipsis',}}>
                        {`${slicedText}...`}
                    </p>
                </HtmlTooltip>
                :
                <p>{text}</p>
            }
        </div>
    } else {
        return '...'
    }
}