
/** Create SHOPIFY */
export const CREATE_SHOPIFY = 'CREATE_SHOPIFY';

/** Add, Edit SHOPIFY product */
export const ADD_SHOPIFY_PRODUCT = 'ADD_SHOPIFY_PRODUCT'

export const EDIT_SHOPIFY_PRODUCT = 'EDIT_SHOPIFY_PRODUCT'

/** Get SHOPIFY */
export const GET_SHOPIFY = 'GET_SHOPIFY';
export const GET_SHOPIFY_SUCCESS = 'GET_SHOPIFY_SUCCESS';

/** Delete SHOPIFY Product */
export const DELETE_SHOPIFY_PRODUCT = 'DELETE_SHOPIFY_PRODUCT';
export const DELETE_SHOPIFY_PRODUCT_SUCCESS = 'DELETE_SHOPIFY_PRODUCT_SUCCESS';


/** SHOPIFY Setup Endpoints */

export const GET_SHOPIFY_SETUP = 'GET_SHOPIFY_SETUP';
export const GET_SHOPIFY_SETUP_SUCCESS = 'GET_SHOPIFY_SETUP_SUCCESS';

export const EDIT_SHOPIFY_SETUP_STATUS = 'EDIT_SHOPIFY_SETUP_STATUS';

export const GET_SHOPIFY_SINGLE_SETUP = 'GET_SHOPIFY_SINGLE_SETUP';
export const GET_SHOPIFY_SINGLE_SETUP_SUCCESS = 'GET_SHOPIFY_SINGLE_SETUP_SUCCESS';

export const PATCH_SHOPIFY_SETUP = 'PATCH_SHOPIFY_SETUP';
export const PATCH_SHOPIFY_SETUP_SUCCESS = 'PATCH_SHOPIFY_SETUP_SUCCESS';

export const POST_SHOPIFY_SETUP = 'POST_SHOPIFY_SETUP';
export const POST_SHOPIFY_SETUP_SUCCESS = 'POST_SHOPIFY_SETUP_SUCCESS';

/** SHOPIFY Orders */

export const GET_SHOPIFY_ORDERS = 'GET_SHOPIFY_ORDERS'
export const GET_SHOPIFY_ORDERS_SUCCESS = 'GET_SHOPIFY_ORDERS_SUCCESS'

export const GET_SHOPIFY_TRANSVERSE = 'GET_SHOPIFY_TRANSVERSE'
export const GET_SHOPIFY_TRANSVERSE_SUCCESS = 'GET_SHOPIFY_TRANSVERSE_SUCCESS'

export const SYNC_RESYNC_SHOPIFY= 'SYNC_RESYNC_SHOPIFY'

export const GET_ORDER_PRODUCT_MAP_SHOPIFY = 'GET_ORDER_PRODUCT_MAP_SHOPIFY'
export const GET_ORDER_PRODUCT_MAP_SHOPIFY_SUCCESS = 'GET_ORDER_PRODUCT_MAP_SHOPIFY_SUCCESS'