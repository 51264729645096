import React, {useEffect} from 'react';
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {AddInviteButton, FullTable} from "components";
import {useModal} from "utils";
import {AddStoreM} from "./fragments";
import {shopifyActions} from "store";
import {shopifyStoresBody, shopifyStoresHead} from "./fragments/constants";

const ACTION_TYPE = 'GET_SHOPIFY_SETUP';

export const ShopifyStore = () => {
    const {setUp} = useSelector((state) => ({
        setUp: state.shopify.setUp,
        setUpSingle: state.shopify.setUp,
    }))

    const dispatch = useDispatch();


    const {open} = useModal();
    const history = useHistory();
    const info = history?.location?.state;

    useEffect(() => {
        const params = {
            ...info,
            limit: 10,
            skip: info?.skip ? info?.skip : 0,
        }
        dispatch(shopifyActions.getShopifySetup(params));
    }, []);


    const goToDetailsPage = (item) => {
        history.push(`/shopify/stores/${item.id}`)
    }

    return (
        <div className='shopify-store-page-wrapper'>
            <div className='flex-end'>
                <AddInviteButton
                    text='Add New Store'
                    handleClick={() => open(<AddStoreM/>)}
                />
            </div>

            <FullTable
                head={shopifyStoresHead}
                body={shopifyStoresBody()}
                loadingType={ACTION_TYPE}
                list={setUp || []}
                listCount={setUp?.count}
                handleClick={goToDetailsPage}
                noText={'Stores'}
            />
        </div>
    );
};

