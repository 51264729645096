import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {shopifyErrorsBody, shopifyErrorsHead} from "./fragments/constants";
import {logsActions} from "store";
import {FullTable} from "components";

export const ShopifyErrors = () => {
    const {logs} = useSelector((state) => ({
        logs: state.logs.allLogs
    }));
    const dispatch = useDispatch();
    const history = useHistory();
    const info = history?.location?.state;
    const [currentId, setCurrentId] = useState(null)

    useEffect(() => {
        const filteredInfo = {
            ...info,
        };
        delete filteredInfo.page;
        delete filteredInfo.tab;

        dispatch(logsActions.getLogsSku({...filteredInfo, store: 'SHOPIFY'}))
    }, [])

    const handleClickToButton = (item) => {
        setCurrentId(item.id)
        const filteredInfo = {
            ...info,
        };
        delete filteredInfo.page;
        delete filteredInfo.tab;
        dispatch(logsActions.resolveLogsSku({...filteredInfo, store: 'SHOPIFY'}, item.id))
    }

    return (
        <FullTable
            head={shopifyErrorsHead}
            body={shopifyErrorsBody(handleClickToButton, currentId)}
            loadingType={'GET_LOGS_SKU'}
            list={logs?.logs}
            listCount={logs?.count}
            noText={'Error'}
        />
    );
}
