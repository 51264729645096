import { call, put, takeLatest } from 'redux-saga/effects';
import { authService } from './auth.service';
import {
    LOG_IN,
    LOG_OUT,
    GET_RECOVERY_LINK,
    RESET_PASSWORD_REQUEST,
    CHANGE_PASSWORD_REQUEST,
    GET_MY_AUTHN,
    GET_MY_PROFILE, RESET_PASSWORD_SUCCESS,
} from './auth.types';
import { httpRequestsOnErrorsActions } from '../http_requests_on_errors';
import { httpRequestsOnLoadActions } from '../http_requests_on_load';
import { httpRequestsOnSuccessActions } from '../http_requests_on_success';

function* logIn({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        const res = yield call(authService.signIn, payload);
        localStorage.setItem('access-token', res.data.token);
        const user = yield call(authService.getAccount);
        localStorage.setItem('userType', JSON.stringify(user.data));
        window.location.replace('/amazon');
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));

    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* logOut({ type }) {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    try {
        yield call(authService.logOut);
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        window.location.replace('/login');
        localStorage.removeItem('access-token');
        localStorage.removeItem('userType');
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        window.location.replace('/login');
        localStorage.removeItem('access-token');
        localStorage.removeItem('userType');
    }
}

function* getLink({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(authService.getLink, payload.email);
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.appendError(type, err.data.message));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    }
}

function* resetPassword(action) {
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    try {
        const res = yield call(action.payload.passwords.type === 'reset' ?
             authService.resetPass
            :
            authService.confirmAccount,
            action.payload.passwords
        );

        localStorage.setItem('access-token', res.data.token);
        const user = yield call(authService.getAccount);
        localStorage.setItem('userType', JSON.stringify(user.data));
        window.location.replace('/users');
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put({
            type: RESET_PASSWORD_SUCCESS,
            payload: res.data,
        });
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
        yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    }
}


function* changePassword(action) {
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        const res = yield call(
            authService.changePasswordService,
            action.payload.data,
        );
        localStorage.setItem('access-token', res.data.accessToken);
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.removeError(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    }
}

function* getMuAuthn(action) {
    try {
        const res = yield call(authService.muAuthnService);
        // localStorage.setItem('poloUserInfo', JSON.stringify(res.data.email) );
        // localStorage.setItem('permissions', JSON.stringify(res.data.roles) );
    } catch (err) {

    }
}

function* getMuProfile(action) {
    try {
        const res = yield call(authService.myProfileService, action.payload.type);
        localStorage.setItem('userType', JSON.stringify(res.data));
    } catch (err) {

    }
}

export const watchAuth = function* watchUserAuth() {
    yield takeLatest(LOG_IN, logIn);
    yield takeLatest(LOG_OUT, logOut);
    yield takeLatest(GET_MY_AUTHN, getMuAuthn);
    yield takeLatest(GET_MY_PROFILE, getMuProfile);
    yield takeLatest(GET_RECOVERY_LINK, getLink);
    yield takeLatest(RESET_PASSWORD_REQUEST, resetPassword);
    yield takeLatest(CHANGE_PASSWORD_REQUEST, changePassword);
};
