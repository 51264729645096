import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import {shopifyActions} from "store";
import {ShopifyMap} from "./fragments/shopifyMap";
import { RightDrawer, shopifyOrderBody, shopifyOrderHead} from "./fragments";
import {SavePage} from "utils";
import {ProductMapModal} from "./fragments/productMapModal";


export const SingleShopifyStore = () => {
    const [showSelected, setShowSelected] = useState('')
    const {id} = useParams();
    const dispatch = useDispatch();
    const {shopifyOrders, setUpSingle} = useSelector(state => state.shopify);
    const history = useHistory();
    const info = history?.location?.state;


    useEffect(() => {
        const filteredInfo = {
            ...info,
            storeSetupId: id,
        };
        delete filteredInfo?.page;
        delete filteredInfo?.singleStore;

        dispatch(shopifyActions.getShopifyOrders(filteredInfo))
    }, [info?.page,info?.skip,info?.limit])


    useEffect(() => {
        dispatch(shopifyActions.getShopifySingleSetup(id));
    }, [dispatch])


    useEffect(() => {
        if (setUpSingle) {
            history.push({state: {...info, singleStore: setUpSingle}});
        }
    }, [setUpSingle])

    const renderTableValues = {
        head: shopifyOrderHead,
        body: shopifyOrderBody,
        actionType: 'GET_SHOPIFY_ORDERS',
        list: shopifyOrders?.orders,
        count: shopifyOrders?.count,
    }


    const handleSearchReq = () => {
        SavePage(history);
    };

    const renderParams = () => {
        const filteredInfo = {
            ...info,
        };
        delete filteredInfo.page;
        delete filteredInfo.tab;

        return filteredInfo;
    };

    const handleOpenProductMap = (item) => {
        setShowSelected(item)
        dispatch(shopifyActions.orderProductMap(item?.shopifySKU))
    }

    return (
        <div>
            <div className="buttons-wrapper" style={{justifyContent: 'flex-end'}}>
                <RightDrawer
                    item={setUpSingle}
                />
            </div>

            <div style={{display: 'flex'}}>
                <ShopifyMap
                    renderParams={renderParams}
                    handleOpen={handleOpenProductMap}
                    head={renderTableValues?.head}
                    body={renderTableValues?.body}
                    loadingType={'GET_SHOPIFY_ORDERS'}
                    type={''}
                    list={renderTableValues?.list}
                    listCount={renderTableValues?.count}
                    handleSearchReq={handleSearchReq}
                />
                {showSelected && <ProductMapModal handleClose={() => setShowSelected(null)}/>}
            </div>
        </div>
    );
};

